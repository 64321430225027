<template>
    <auth-layout>
        <div class="ce-login-card">
            <h3 class="ce-login-card-title">Save Browser</h3>
            <div class="ce-login-card-item-wrap">
                <p>Would you like to save this computer (device) as a recognized computer (device) for
                    accessing your dashboard in the future?<br>
                    PLEASE NOTE IF YOU SELECT NO, THEN THIS COMPUTER (DEVICE) WILL NOT BE SAVED AND YOU WILL
                    BE ASKED TO VERIFY ACCOUNT FOR FUTURE LOGINS FROM THIS COMPUTER(DEVICE). </p>
                <div class="form-group">
                    <div class="radio-btn">
                        <input type="radio" name="code_option" value="yes" v-model="save_browser"
                               checked="checked">
                        <span style="font-weight: bold;">Yes</span>
                    </div>
                    <div class="radio-btn">
                        <input type="radio" name="code_option" value="no" v-model="save_browser"
                               checked="checked">
                        <span style="font-weight: bold;">No</span>
                    </div>
                </div>
            </div>

            <div class="ce-login-btn-wrap">
                <button v-on:click="saveBrowser(save_browser)" class="btn ce-btn-login-submit">
                    Submit
                </button>
            </div>
        </div>
    </auth-layout>
</template>
<script>
    import {mapActions} from 'vuex';
    import {ACTION_SAVE_BROWSER} from '@/store/modules/User';
    import AuthLayout from '@/layouts/AuthLayout';

    export default {
        components: {
            'auth-layout': AuthLayout
        },
        data() {
            return {
                save_browser: 'yes'
            }
        },
        methods: {
            ...mapActions('User',{
                saveBrowser: ACTION_SAVE_BROWSER
            })
        }
    }
</script>
